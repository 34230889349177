import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { useIntl } from 'gatsby-plugin-react-intl';

import Layout from '../components/Layout';
import NotFound from '../components/NotFound';
import Seo from '../components/Seo';
import Builder from '../components/Builder';

const Concept = () => {
  const data = useStaticQuery(graphql`
    query ConceptQuery {
      concepts: allWpConcept {
        nodes {
          builder {
            contentFr {
              text
              imagesPosition
              images {
                localFile {
                  url
                }
              }
            }
            contentEn {
              text
              imagesPosition
              images {
                localFile {
                  url
                }
              }
            }
          }
          id
        }
      }
    }
  `);

  const intl = useIntl();
  const { locale } = intl;

  if (!data?.concepts?.nodes || data?.concepts?.nodes.length === 0) {
    return <NotFound />;
  }

  return (
    <Layout>
      <Seo title={intl.formatMessage({ id: 'concept.title' })} />
      <div className="page-title">
        {intl.formatMessage({ id: 'concept.title' })}
      </div>
      <div className="page-title-underline" />
      <Builder
        sections={
          locale === 'en' &&
          data?.concepts?.nodes.length > 0 &&
          data?.concepts?.nodes[0]?.builder?.contentEn?.length > 0
            ? data.concepts.nodes[0].builder.contentEn
            : data?.concepts?.nodes[0]?.builder?.contentFr
        }
      />
    </Layout>
  );
};

export default Concept;
